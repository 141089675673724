export default {
  data() {
    return {
      themeColors: {},
    };
  },
  created() {
    const {
      isDark,
      defaults: { dark, light },
    } = this.$vuetify.theme;
    if (isDark) this.themeColors = dark;
    else this.themeColors = light;
  },
};
