<template>
  <div class="editable" ref="editable" :key="editMode">
    <p class="label">{{ label }}</p>
    <div class="text-area" v-if="!editMode">
      <p class="text" v-if="fieldValue">{{ fieldValue }}</p>
      <p class="text grey--text" v-else>{{ placeholder }}</p>
      <v-btn text color="primary" class="capitalize" @click="makeEditAble">
        {{ $vuetify.lang.t("$vuetify.edit") }}
      </v-btn>
    </div>
    <form class="text-area" v-else @submit.prevent="textUpdated">
      <input
        class="input"
        ref="inputField"
        :type="type"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        v-model="localValue"
        @blur="textUpdated"
      />
      <v-btn text color="primary" class="capitalize" type="submit">{{
        $vuetify.lang.t("$vuetify.update")
      }}</v-btn>
    </form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "editable-field",
  props: {
    value: [String, Number],
    label: String,
    type: { default: "text" },
    max: { type: Number, default: 1 },
    min: { type: Number, default: 1 },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      fieldValue: this.value,
      localValue: this.value,
      editMode: false,
    };
  },
  methods: {
    ...mapMutations(["SET_BOTTOM_BAR"]),
    textUpdated() {
      const { type, min, localValue } = this;
      if (type === "number") {
        if (+localValue < +min) {
          window.showAlert("Number cannot be  negative.");
          return;
        }
      }
      this.fieldValue = this.localValue;
      this.$emit("input", this.localValue);
      this.$emit("updated", this.localValue);
      this.editMode = false;

      this.$nextTick(() => {
        this.$refs.editable.focus();
        this.SET_BOTTOM_BAR(false);
      });
    },
    makeEditAble() {
      this.editMode = true;
      this.$nextTick(() => {
        this.$refs.inputField.focus();
        this.SET_BOTTOM_BAR(true);
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.editable {
  margin: 10px 0;
  .label {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    opacity: 0.6;
    margin-bottom: 0;
  }

  .text-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
    }

    input {
      border: none;
      box-shadow: none;
      outline: none;
      flex: 1;
    }

    p,
    input {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }
}
</style>
