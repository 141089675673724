<template>
  <div>
    <div
      v-for="option in options"
      :key="option.value"
      :class="{
        active: isActive(option.value),
        'has-icon': !!option.icon,
        prepend
      }"
      :style="
        isActive(option.value) && `border:0.5px solid ${themeColors.primary}`
      "
      class="lists"
    >
      <div class="lists--item" v-ripple @click="makeSelection(option.value)">
        <v-icon
          v-if="!prepend"
          :color="isActive(option.value) ? 'primary' : ''"
        >
          {{ `mdi-radiobox-${isActive(option.value) ? "marked" : "blank"}` }}
        </v-icon>
        <div class="opt-detail" :class="prepend && option.icon && 'has-icon'">
          <div class="prepend-icon" v-if="prepend && option.icon">
            <img
              v-if="option.icon && !option.isIcon"
              :src="option.icon"
              alt=""
            />
            <v-icon v-if="option.icon && option.isIcon">
              {{ option.icon }}
            </v-icon>
          </div>

          <div>
            <p class="subtitle-2 capitalize">{{ option.label }}</p>
            <span class="small" v-if="option.subtitle">
              {{ option.subtitle }}
            </span>
          </div>
        </div>

        <v-icon v-if="prepend" :color="isActive(option.value) ? 'primary' : ''">
          {{
            isActive(option.value)
              ? "mdi-check-circle-outline"
              : "mdi-checkbox-blank-circle-outline"
          }}
        </v-icon>

        <img v-if="option.icon && !option.isIcon" :src="option.icon" alt="" />
      </div>
      <v-expand-transition>
        <slot name="bottom" v-if="option.showBottomSlot"></slot>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import getThemeColors from "@/mixin/get-theme-colors";
export default {
  name: "radio-buttons",
  mixins: [getThemeColors],
  props: {
    value: [String, Array],
    options: Array,

    multiple: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: Boolean,
      default: false
    },
    iconCheck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: this.value
    };
  },
  methods: {
    makeSelection(value) {
      if (this.multiple) {
        const index = this.selected.findIndex(val => val === value);
        if (index === -1) this.selected.push(value);
        else this.selected.splice(index, 1);
      } else {
        this.selected = value;
      }
      this.$emit("input", this.selected);
    },
    isActive(value) {
      if (this.multiple) {
        return this.selected.includes(value);
      } else {
        return this.selected === value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lists {
  background: #f9f9f9;
  border-radius: 12px;
  margin-bottom: 0.6rem;
  border: 0.5px solid transparent;

  &.active {
    background: #f5f2e9;
  }

  &--item {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    border-radius: 12px;

    .opt-detail {
      margin-left: 1rem;
      p {
        margin-bottom: 0;
      }

      span.small {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        opacity: 0.7;
      }
    }

    &.has-icon {
      padding-right: 3rem;
      position: relative;

      img {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }

  &.prepend {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .lists--item {
      align-items: center;
      justify-content: space-between;

      .opt-detail {
        margin-left: 0;
        display: flex;
        align-items: center;

        &.has-icon {
          .prepend-icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
