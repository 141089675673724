<template>
  <app-page hideAppBar>
    <AdvertisementCard
      :loading="ad_loading"
      @cta-clicked="ctaClickHandler"
      @icon-clicked="ctaClickHandler"
      :advertisement="advertisement"
    />
    <div class="app-bar">
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div class="px-4 pb-10">
      <v-img
        center
        contain
        max-height="250"
        src="@/components/svg/interested-in.svg"
      ></v-img>

      <h1 class="my-5">
        {{ $vuetify.lang.t("$vuetify.paws_crossed") }} {{ activePetDetail.name }}
        {{ $vuetify.lang.t("$vuetify.become_friends") }} !!!
      </h1>

      <h3>{{ $vuetify.lang.t("$vuetify.what_are_you_interested_in") }}</h3>

      <RadioBtn v-model="interestedIn" :options="interestedOptions">
        <template v-slot:bottom>
          <div class="px-3 py-5" v-if="interestedIn === 'co_owner'">
            <h3 class="mb-2">
              {{ $vuetify.lang.t("$vuetify.how_would_you_like_to_own") }}
            </h3>
            <RadioBtn
              v-model="ownType"
              :options="ownOptions"
              prepend
              icon-check
              multiple
            />

            <h3>
              {{ $vuetify.lang.t("$vuetify.please_choose_in_which") }}
              {{ activePetDetail.name }}
              {{ $vuetify.lang.t("$vuetify.in_house") }}?
            </h3>

            <div class="days">
              <div
                v-for="day in daysDetails"
                :key="day.state"
                class="days-item"
              >
                <span class="state" :class="`state-${day.state}`"></span>
                <span class="text">{{ day.text }}</span>
              </div>
            </div>

            <WeekDays v-model="days" />
          </div>
        </template>
      </RadioBtn>

      <h3 class="mt-10">{{ $vuetify.lang.t("$vuetify.disclaimer") }}</h3>
      <p class="desclaimer-text">
        {{
          activePetDetail.disclaimer ||
            `{!! ${$vuetify.lang.t("$vuetify.no_description")} !!}`
        }}
      </p>

      <h3 class="mt-5">
        {{ $vuetify.lang.t("$vuetify.your_contact_details") }}: 
      </h3>
      <EditAble
        v-model="phone"
        type="phone"
        :label="`${$vuetify.lang.t('$vuetify.phone')} (+31-xxx-xxx-xxx)`"
        :placeholder="$vuetify.lang.t('$vuetify.enter_phone')"
        @updated="(val) => updateUser(val, 'contact')"
      />

      <EditAble
        v-model="email"
        type="email"
        :label="$vuetify.lang.t('$vuetify.email')"
        :placeholder="$vuetify.lang.t('$vuetify.enter_email')"
        @updated="(val) => updateUser(val, 'email')"
      />

      <!-- <EditAble
        v-model="minimum_amount"
        type="number"
        label="Animals You Want To adopt"
        :max="activePetDetail.minimum_amount"
        placeholder="No. of pets"
        v-if="interestedIn === 'owner'"
      /> -->
      <app-button
        class="mt-10"
        :loading="loading"
        :disabled="userSaving"
        @click="submitRequest"
        >{{ $vuetify.lang.t("$vuetify.submit_request") }}</app-button
      >
    </div>
  </app-page>
</template>

<script>
import RadioBtn from "@/components/form/radio-buttons";
import WeekDays from "@/components/form/weekdays";
import EditAble from "@/components/form/editable-field";
import AdvertisementCard from "@/components/advertisement/advertisement-card-list";

import { createNamespacedHelpers, mapMutations } from "vuex";
const userModule = createNamespacedHelpers("user");
const petModule = createNamespacedHelpers("pet");

import parsePhoneNumber from "libphonenumber-js";
export default {
  name: "interested-in",
  components: { RadioBtn, WeekDays, EditAble, AdvertisementCard },
  data() {
    return {
      interestedIn: "",
      ownType: [],
      phone: "",
      email: "",
      no_of_animals: 1,
      days: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
      },
      isValidContact: true,
      userSaving: false,
    };
  },
  computed: {
    ...userModule.mapState(["user"]),
    ...petModule.mapState([
      "activePetDetail",
      "loading",
      "advertisement",
      "ad_loading",
    ]),
    interestedOptions() {
      const { co_adoption, adoption } = this.activePetDetail;
      const arr = [
        {
          label: this.$vuetify.lang.t("$vuetify.adoption"),
          value: "owner",
          subtitle:
            "",
          icon: require("@/components/svg/icon/home.svg"),
          showBottomSlot: false,
          isIcon: false,
          show: adoption,
        },
        {
          label: this.$vuetify.lang.t("$vuetify.co_ownership"),
          value: "co_owner",
          subtitle:
            "",
          icon: require("@/components/svg/icon/bone-icon.svg"),
          showBottomSlot: true,
          isIcon: false,
          show: co_adoption,
        },
      ];

      return arr.filter((a) => a.show);
    },
    ownOptions() {
      return [
        {
          label: this.$vuetify.lang.t("$vuetify.go_for_a_walk"),
          value: "walk",
          icon: "mdi-dog-service",
          isIcon: true,
        },
        {
          label: this.$vuetify.lang.t("$vuetify.in_house"),
          value: "house",
          icon: "mdi-home",
          isIcon: true,
        },
        // {
        //   label: this.$vuetify.lang.t("$vuetify.travel_with_pet"),
        //   value: "travel",
        //   icon: "mdi-compass-outline",
        //   isIcon: true,
        // },
      ];
    },
    daysDetails() {
      return [
        {
          state: 1,
          text: this.$vuetify.lang.t("$vuetify.one_tap"),
        },
        {
          state: 2,
          text: this.$vuetify.lang.t("$vuetify.two_tap"),
        },
        {
          state: 3,
          text: this.$vuetify.lang.t("$vuetify.triple_tap"),
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["SET_BOTTOM_BAR"]),
    ...petModule.mapMutations(["SET_ADVERTISEMENT"]),
    ...petModule.mapActions([
      "MAKE_ADOPTION_REQUEST",
      "FETCH_PET_ADVERTISEMENT",
    ]),
    ...userModule.mapActions(["UPDATE_USER_PROFILE"]),

    async submitRequest() {
      try {
        if (!this.interestedIn) {
          window.showAlert(
            "Please select your what you are interested in.",
            "Please Select."
          );
          return;
        }

        if (
          this.interestedIn === "owner" &&
          this.no_of_animals > this.activePetDetail.minimum_amount
        ) {
          window.showAlert(
            `Please Select maximum ${this.activePetDetail.minimum_amount} of pets`,
            "Input Error"
          );
          return;
        }

        if (!this.email || !this.phone) {
          let text = "Please Enter the phone.";
          if (!this.email) text = "Please Enter the valid email.";
          window.showAlert(text, "Input Error");
          return;
        }

        // const isValid = this.isValidNumber(this.phone);
        // if (!isValid || !this.isValidContact)
        //   return window.showAlert(
        //     "Please Enter the valid Phone Number ",
        //     "Invalid Number"
        //   );

        await this.MAKE_ADOPTION_REQUEST({
          animal_id: this.$route.params.id,
          type: this.interestedIn,
          // no_of_animals: this.interestedIn === "owner" ? this.no_of_animals : 1,
          detail: {
            ownType: this.ownType,
            days: this.days,
          },
        });
        this.$router.replace("/pet-request-submitted");
      } catch (error) {
        console.log(error);
      }
    },
    async updateUser(value, type) {
      let userValue = value;
      let errorMessage = "";
      this.userSaving = true;

      if (type === "email") {
        if (this.user.email === value) return;
      } else {
        // const num = this.isValidNumber(value);
        // if (!num) errorMessage = `Please enter the valid ${type}`;
        userValue = value;
      }

      if (!value) {
        errorMessage = `Please enter the valid ${type}`;
        this.userSaving = false;
        return;
      }

      if (errorMessage) {
        window.showAlert(errorMessage, "Some Error");
      }

      const resp = await this.UPDATE_USER_PROFILE({
        [type]: userValue,
      });

      if (!resp) this.isValidContact = false;

      this.userSaving = false;
    },
    isValidNumber(number) {
      const phoneNumber = parsePhoneNumber(number);
      if (!phoneNumber) return false;
      if (!phoneNumber.isPossible()) return false;
      console.log({ phoneNumber, isPossible: phoneNumber.isPossible() });
      return phoneNumber.number;
    },
    ctaClickHandler() {
      window.open(this.advertisement.cta_links, "_system", "location=yes");
    },
  },
  created() {
    this.FETCH_PET_ADVERTISEMENT();
    const { contact, email } = this.user;
    this.email = email;
    this.phone = contact;
  },
  beforeDestroy() {
    this.SET_BOTTOM_BAR(false);
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #010101;
}

.desclaimer-text {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.app-bar {
  padding: 0.8rem 1rem;
  background: #fff;
  z-index: 11;
}

.days {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;

  &-item {
    display: flex;
    align-items: center;
    margin: 5px 0;

    span {
      display: inline-block;

      &.text {
        font-size: 10px;
        line-height: 12px;
        color: #868686;
        margin-left: 10px;
      }

      &.state {
        height: 12px;
        width: 12px;
        background-color: #e5e7eb;
        border-radius: 50%;
        overflow: hidden;
        position: relative;

        &-1 {
          background-color: #0dbff2;
        }

        &-2 {
          &:before {
            content: "";
            top: 0;
            height: 50%;
            width: 100%;
            left: 0;
            background-color: #f6c15a;
            position: absolute;
          }
        }

        &-3 {
          &:after {
            content: "";
            width: 100%;
            top: 50%;
            left: 0;
            bottom: 0;
            background-color: #0dbff2;
            position: absolute;
          }
        }
      }
    }
  }
}
</style>
