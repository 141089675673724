<template>
  <div class="weekdays">
    <div
      v-for="day in weekDays"
      :key="day"
      class="weekdays--item capitalize"
      :class="`state-${selectedDays[day]}`"
      @click="makeSelection(day)"
      v-ripple
    >
      <span>{{ day | trim(3) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "weekdays",
  props: ["value"],
  data() {
    return {
      selectedDays: this.value,
    };
  },
  methods: {
    makeSelection(day) {
      if (this.selectedDays[day] === 3) {
        this.selectedDays[day] = 0;
      } else {
        this.selectedDays[day]++;
      }
      this.$emit("input", { ...this.selectedDays });
    },
  },
  computed: {
    weekDays() {
      return [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.weekdays {
  display: flex;
  flex-wrap: wrap;

  &--item {
    margin: 0.3rem;
    height: 60px;
    width: 60px;
    color: #333;
    display: grid;
    place-items: center;
    border: 1px solid #dadada;
    border-radius: 9px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 50%;
    text-transform: uppercase;
    position: relative !important;
    overflow: hidden;

    span {
      position: relative;
      z-index: 111;
    }

    // &.state-0{

    // }
    &.state-1 {
      background-color: #0dbff2;
      border-color: #0dbff2;
      color: #fff;
    }
    &.state-2 {
      &:before {
        content: "";
        top: 0;
        height: 50%;
        width: 100%;
        left: 0;
        background-color: #f6c15a;
        position: absolute;
      }
    }
    &.state-3 {
      &:after {
        content: "";
        width: 100%;
        top: 50%;
        left: 0;
        bottom: 0;
        background-color: #0dbff2;
        position: absolute;
      }
    }
  }
}
</style>
