<template>
  <loader :loading="loading" height="56px" color="primary" size="30">
    <v-row class="advertisement" no-gutters align="center" v-if="advertisement">
      <v-col cols="2">
        <v-img
          :src="advertisement.media[0]"
          cover
          center
          height="40"
          width="40"
          style="border-radius:5px"
        ></v-img>
      </v-col>
      <v-col cols="6">
        <div class="pr-3">
          <div class="sponser">
            <span class="golden">Ad</span>
            <span class="text">
              {{ $vuetify.lang.t("$vuetify.sponsored_content") }}
            </span>
          </div>
          <p class="light-text">
            {{ advertisement.title | trim(25, "...") }}
          </p>
        </div>
      </v-col>
      <v-col cols="4" class="text-right">
        <button
          class="cta-btn primary white--text"
          v-ripple
          @click="$emit('cta-clicked')"
        >
          {{ advertisement.cta_label }}
        </button>
        <v-icon class="ml-2" @click="$emit('icon-clicked')"
          >mdi-chevron-down</v-icon
        >
      </v-col>
    </v-row>
  </loader>
</template>

<script>
export default {
  name: "advertisement-card-list",
  props: {
    advertisement: { type: Object },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.advertisement {
  background: #fbfbfb;
  padding: 0.6rem 1rem;

  .sponser {
    .golden {
      font-size: 8px;
      color: #fff;
      padding: 3px;
      border-radius: 5px;
    }

    .text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin-left: 8px;
    }
  }
  .light-text {
    font-size: 12px;
    color: #000000;
    opacity: 0.5;
    margin: 0;
  }

  .cta-btn {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }
}
</style>
